.imageFluid {
  max-height: 80vh;
  width: 100vw;
  object-fit: cover;
  object-position: top center;
}

.specificationContainer {
  padding: 50px 0;
  margin: 0 25px;
  h2 {
    font-size: 42px;
    font-weight: bold;
    color: #808080;
    margin-bottom: 50px;
    margin-top: 50px;
  }
}

.tableWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  > div {
    display: flex;
    flex-direction: column;
    p {
      font-weight: bold;
    }
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
  table {
    margin-bottom: 16px;
  }
}

.table {
  border-spacing: 0;
  border: 1px solid rgba($color: #2d2d2d, $alpha: 0.05);

  th {
    padding: 0 16px;
  }

  .row {
    &.odd {
      background-color: rgba($color: #2d2d2d, $alpha: 0.05);
    }
  }

  .cell {
    text-align: center;
    padding: 6px 16px;
  }
  .headerCell {
    font-weight: bold;
  }
  .footerCell {
    font-weight: bold;
  }
  span {
    font-size: 12px;
  }
}

.hiddenHeader {
  display: none;
}

.center {
  text-align: center;
}

.gallery {
  margin: 0 25px 50px 25px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 25px;
  column-gap: 25px;

  @media screen and (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
  > div {
    aspect-ratio: 1;
    position: relative;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.larger {
  font-size: 20px;
}

.imageContainer {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-bottom: 30px;
  justify-content: space-between;
  img {
    object-fit: cover;
    object-position: center;
    width: 50%;
  }
}
