.wrapper {
  margin-top: 65px;
}
.introduction {
  padding: 100px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  > * {
    flex: 1;
    margin: 0 25px;
  }
  img {
    width: 100%;
  }
  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
}

.heroText {
  color: #808080;
  font-size: 42px;
  font-weight: bold;
  span {
    color: #fbaf18;
  }
}

.content {
  p {
    color: #808080;
    font-size: 24px;
  }
}

.productButtonGroup {
  margin: 0 -8px;
}

.maps {
  border: none;
  width: 100%;
  // height: 400px;

  height: calc(100% - 100px - 15px);

  @media screen and (max-width: 820px) {
    height: 400px;
  }
}
