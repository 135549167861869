.wrapper {
  z-index: 2;
  position: fixed;
  top: 0;
  width: 100vw;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  transition: 0.25s ease;
  height: 95px;

  &.scrolled {
    background-color: #ffffff;
    height: 65px;
  }

  &.hidden {
    background-color: #ffffff;
    height: 95px !important;
    > .linkWrapper,
    .buttonWrapper {
      opacity: 0;
      pointer-events: none;
    }
  }

  @media screen and (max-width: 1120px) {
    > .linkWrapper {
      .link {
        display: none;
      }
    }
  }

  @media screen and (max-width: 866px) {
    > .linkWrapper,
    .buttonWrapper {
      opacity: 0;
      display: none;
      pointer-events: none;
    }
    .brandTitle {
      display: none;
    }
  }

  > * {
    flex: 1;
    display: flex;
    justify-content: center;
    &:first-child {
      justify-content: flex-start;
    }
    &:last-child {
      justify-content: flex-end;
    }
  }
  @media screen and (max-width: 866px) {
    > * {
      &:first-child {
        justify-content: space-between;
      }
    }
  }
}

.menuWrapper {
  display: flex;
  align-items: center;
  margin: 0 -8px;
  user-select: none;
  > * {
    margin: 0 8px;
  }
  .menu {
    display: flex;
    align-items: center;
    border-radius: 100%;
    justify-content: center;
    cursor: pointer;
    height: 42px;
    width: 42px;
    transition: 0.25s ease;
    svg {
      fill: #ffffff;
    }
    &:hover {
      background-color: rgba($color: #808080, $alpha: 0.15);
    }
    &.active {
      svg {
        fill: #2d2d2d;
      }
    }
    &.scrolled {
      svg {
        fill: #2d2d2d;
      }
    }
  }

  .logo {
    height: 42px;
  }
}

.linkWrapper {
  transition: 0.25s ease;
  .link {
    margin: 0 8px;
    color: #ffffff;
    text-decoration: none;
    padding: 8px 12px;
    border-radius: 5px;
    transition: 0.25s ease;
    white-space: nowrap;
    font-weight: bold;
    &:hover {
      background-color: rgba($color: #ffffff, $alpha: 0.25);
    }
  }
  &.scrolled {
    .link {
      color: #2d2d2d;
      &:hover {
        background-color: rgba($color: #808080, $alpha: 0.15);
      }
    }
  }
}

.buttonWrapper {
  transition: 0.25s ease;
  margin: 0 -8px;
  > * {
    white-space: nowrap;
  }
}

.brandTitle {
  white-space: nowrap;
  font-size: 24px;
  opacity: 0;
  transition: 0.25s ease;
  color: #2d2d2d;
  padding: 8px 12px;
  border-radius: 5px;
  text-decoration: none;
  &:hover {
    background-color: rgba($color: #808080, $alpha: 0.15);
  }
  &.shown {
    opacity: 1;
  }
}
