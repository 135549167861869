.wrapper {
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: inline-block;
  overflow: hidden;
  margin: 0 8px;
  text-decoration: none;

  > .content {
    position: relative;
    z-index: 1;
    white-space: nowrap;
  }
}

.light {
  background-color: #fbaf18;
  color: #ffffff;
  position: relative;

  > .decor::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0%;
    // opacity: 0;
    background-color: #e09d16;
    transition: 0.25s ease;
  }

  &:hover {
    // background-color: rgba($color: #fbaf18, $alpha: 0.75);
    > .decor::before {
      opacity: 1;
      height: 100%;
      bottom: 0;
      top: unset;
    }
  }
}
