.wrapper {
  padding: 100px 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  > * {
    flex: 1;
    margin: 0 25px;
  }
  img {
    width: 100%;
  }
  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
  .content {
    p {
      color: #808080;
      font-size: 24px;
    }
    svg {
      fill: #808080;
    }
  }
}

.heroText {
  color: #808080;
  font-size: 42px;
  font-weight: bold;
  span {
    color: #fbaf18;
  }
}

.wrapperSection {
  padding: 0 0 50px 0;
  > * {
    flex: 1;
    margin: 0 25px;
  }
  .content {
    p {
      color: #808080;
      font-size: 18px;
    }
    &.center {
      margin: 25px 25px 0 25px;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      p {
        max-width: 700px;
      }
    }
  }
}
