.outerWrapper {
  // background-color: #f9f9f9;
  padding: 50px 0 100px 0;
}

.wrapper {
  margin: 0 25px;
  > .header {
    font-size: 42px;
    font-weight: bold;
    color: #808080;
    margin-bottom: 50px;
  }
}

// .sliderWrapper {
// }

.card {
  cursor: pointer;
  // padding: 16px;
  // background-color: #808080;
  border-radius: 5px;
  transition: 0.25s ease;
  text-decoration: none;
  .img {
    margin-bottom: 10px;
    width: 100%;
    img {
      border-radius: 5px;
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  h4 {
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    margin: 0 0 10px 0;
  }
  p {
    font-size: 14px;
    color: #ffffff;
    margin: 0;
  }
}

.ratio {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 100%;
  position: relative;

  &::before {
    content: '';
    width: 0;
    padding-bottom: calc(100% / (16 / 9)); /* here you can place any ratio */
    grid-area: 1 / 1 / 1 / 1;
  }
  > *:first-child {
    grid-area: 1 / 1 / 1 / 1; /* the same as ::before */
    background: rgba(0, 0, 0, 0.1); /* just for instance */
  }
}

.player {
  width: 100%;
  height: 100%;
}
