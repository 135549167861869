.partnerListWrapper {
  margin: 0 25px 0 25px;
  padding: 0 0 25px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  > * {
    margin: 15px;
  }
  img {
    height: 100%;
    max-height: 75px;
    object-fit: contain;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;

  p {
    text-align: center;
    margin: 0 25px;
    max-width: 600px;
    color: #808080;
    font-size: 24px;
    &.small {
      font-size: 18px;
    }
  }
  svg {
    fill: #808080;
  }
}
