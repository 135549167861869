@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;700&display=swap');

html {
  height: -webkit-fill-available;
}

body {
  margin: 0;
  font-family: 'Rubik', 'Kanit', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ol,
ul {
  > li {
    margin-bottom: 10px;
    margin-top: 30px;
  }
  div {
    ol,
    ul > li {
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }
}

.accordion__button {
  width: calc(100% - 18px - 18px) !important;
  user-select: none !important;
  font-weight: bold;
  font-size: 22px;
}

.accordion {
  max-width: calc(100vw - 25px - 25px);
  margin: 0 auto;
  margin-bottom: 50px;
}

.accordion__header {
  display: inline-block;
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      margin-left: 5px;
      object-fit: cover;
      object-position: center;
      height: 20px;
      &:first-child {
        margin-left: 10px;
      }
    }
  }
}
