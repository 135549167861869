.wrapper {
  width: 100vw;
  height: 100vh;
  background-color: lightgray;
  position: relative;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 1s ease;
    transform: translateY(0%);
    &.down {
      transform: translateY(-100%);
    }
    &.up {
      transform: translateY(100%);
    }
  }
}
