.wrapper {
  padding: 25px 0;
}

.container {
  margin: 0 25px;
  padding: 25px 0;
  background-color: rgba($color: #2d2d2d, $alpha: 0.05);
}

.bulletWrapper {
  margin: 0 25px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  > div {
    margin-right: 25px;
    position: relative;
    text-transform: capitalize;
    &:first-child {
      font-weight: bold;
    }
    &:not(:last-child) {
      &:after {
        content: '';
        background-color: #2d2d2d;
        width: 8px;
        height: 1px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateX(calc(50% + 12.5px));
      }
    }
  }
}
