.wrapper {
  padding: 100px 0;
  &.contactOnly {
    padding: 50px 0 0 0;
    .contactItem {
      padding: 25px;
    }
  }
}

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 25px;
}

.description {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 42px;
  font-weight: bold;

  h2 {
    margin-top: 0;
    margin-bottom: 50px;
    font-size: 42px;
  }
  img {
    max-width: 236px;
    margin-bottom: 25px;
  }
}

.contactList {
  flex: 1;
  .contactItem {
    background-color: #2d2d2d;
    color: #ffffff;
    display: flex;
    align-items: center;
    padding: 50px;
    font-size: 24px;
    transition: 0.25s ease;
    text-decoration: none;

    img {
      margin-right: 25px;
      width: 52px;
      height: 52px;
    }

    p {
      margin: 0;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }
  }
}
