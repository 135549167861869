.wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  > div {
    display: flex;
    justify-content: flex-end;
  }
}

.scrollDown {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 34px;

  @media screen and (max-width: 375px) {
    display: none;
  }
}

.contentWrapper {
  position: relative;
  width: 60%;
  padding: 34px;
  background-color: #fbaf18;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;

  display: flex;
  flex-direction: row;

  @media screen and (max-width: 375px) {
    width: 100%;
  }
}

.counter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 34px;

  color: black;
  -webkit-text-fill-color: #fbaf18; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #ffffff;
  font-size: 62px;
  font-weight: bold;
  opacity: 1;
  transition: 0.25s ease;
}

.content {
  color: #ffffff;
  h2 {
    margin: 0;
    font-size: 24px;
  }
  p {
    margin: 0;
    font-size: 14;
  }
  opacity: 1;
  transition: 0.25s ease;
}

.button {
  display: flex;
  align-items: center;
  opacity: 1;
  transition: 0.25s ease;
  > a {
    border: 1px solid #ffffff;
  }
  @media screen and (max-width: 375px) {
    display: none;
  }
}

.fadedOut {
  opacity: 0;
}

.control {
  position: absolute;
  top: 0;
  left: 34px;
  display: flex;

  transform: translateY(-100%);

  > * {
    user-select: none;
    background-color: rgba($color: #fbaf18, $alpha: 0.5);
    padding: 8px 16px;
    border: 1px solid #fbaf18;
    color: #ffffff;
    cursor: pointer;

    &:hover {
      background-color: rgba($color: #fbaf18, $alpha: 1);
    }
    &:first-child {
      > div {
        transform: rotate(90deg);
      }
    }
    &:last-child {
      > div {
        transform: rotate(90deg);
      }
    }
  }
}
