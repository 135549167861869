.App {
  // background-color: darkgray;
  // min-height: 100vh;
  color: #2d2d2d;
}

.site {
  min-height: 50vh;
  max-width: 100vw;
  overflow: hidden;
}

.NotFound {
  margin: 315px 0 250px 0;
  .msgWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .msg {
      font-size: 32px;
    }
    a {
      color: #808080;
    }
  }
}
