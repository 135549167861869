.imageFluid {
  max-height: 80vh;
  width: 100vw;
  object-fit: cover;
  object-position: top center;
}

.specificationContainer {
  padding: 50px 0;
  margin: 0 25px;
  h2 {
    font-size: 42px;
    font-weight: bold;
    color: #808080;
    margin-bottom: 50px;
    margin-top: 50px;
  }
}

.tableWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  > table {
    margin-bottom: 16px;
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
}

.table {
  border-spacing: 0;
  border: 1px solid rgba($color: #2d2d2d, $alpha: 0.05);

  th {
    padding: 0 16px;
  }

  .row {
    &.odd {
      background-color: rgba($color: #2d2d2d, $alpha: 0.05);
    }
  }

  .cell {
    padding: 6px 16px;
    text-align: center;
  }
  .headerCell {
    font-weight: bold;
  }
  .footerCell {
    font-weight: bold;
  }
  span {
    font-size: 12px;
  }
}

.tab {
  li {
    margin-top: 10px;
  }
}

.larger {
  font-size: 20px;
}
