.wrapper {
  padding: 100px 0;
  margin: 0 25px;
}

.galleryContainer {
  padding: 0 25px 0 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 25px;
  column-gap: 25px;

  @media screen and (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.header {
  font-size: 42px;
  font-weight: bold;
  color: #808080;
  margin-bottom: 50px;
}

.image {
  aspect-ratio: 1;
  position: relative;
  transition: 0.25s ease;

  &.less {
    opacity: 0.5;
  }

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  > .caption {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 16px;
    > a {
      text-align: right;
      color: #ffffff;
      svg {
        fill: #ffffff;
        height: 13px;
      }
      &:hover {
        opacity: 0.5;
      }
    }
  }
}
