.wrapper {
  z-index: 9999;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: calc(100vh - 95px);
  transition: 0.25s ease;
  opacity: 0;
  background-color: #ffffff;
  pointer-events: none;
  &.shown {
    opacity: 1;
    pointer-events: auto;
  }
}

.container {
  height: calc(100% - 25px);
  width: calc(100% - 25px - 25px);
  padding: 0 25px 25px 25px;
  display: flex;
  justify-content: flex-start;
}

.mainContentWrapper {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
}

.linkWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  .link {
    font-size: 64px;
    color: #2d2d2d;
    text-decoration: none;
    padding: 8px 24px;
    border-radius: 5px;
    transition: 0.25s ease;
    white-space: nowrap;
    @media screen and (max-width: 611px) {
      font-size: 41px;
    }
    @media screen and (max-width: 420px) {
      font-size: 26px;
    }
    &:hover {
      background-color: rgba($color: #808080, $alpha: 0.15);
    }
  }
}

.buttonWrapper {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 -8px;
  .decor {
    max-width: 140px;
    margin: 0 8px 25px 8px;
    @media screen and (max-width: 1190px) {
      max-width: 150px;
    }
  }
  .buttonContainer {
    flex: 1;
    white-space: nowrap;
    > * {
      font-size: 18px;
      @media screen and (max-width: 420px) {
        font-size: 14px;
      }
    }
  }
}

.featuredContentWrapper {
  display: flex;
  flex: 2;
  margin: 0 -8px;
  @media screen and (max-width: 1190px) {
    display: none;
  }
  > * {
    margin: 0 8px;
  }
  .card {
    display: flex;
    flex: 1;
    overflow: hidden;
    border-radius: 5px;
    background-color: #808080;
    img,
    video {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
    video {
      left: 0;
    }
  }

  display: none;
}
