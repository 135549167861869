.outerWrapper {
  background-color: #f9f9f9;
  padding: 100px 0;
}

.wrapper {
  margin: 0 25px;
  > .header {
    font-size: 42px;
    font-weight: bold;
    color: #808080;
    margin-bottom: 50px;
  }
}

.sliderWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 35px;
  @media screen and (max-width: 838px) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 25px;
  }
  @media screen and (max-width: 545px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.card {
  cursor: pointer;
  padding: 16px;
  background-color: #808080;
  border-radius: 5px;
  transition: 0.25s ease;
  text-decoration: none;
  &:hover {
    transform: scale(1.05);
  }
  &.less {
    opacity: 0.5;
  }
  .img {
    margin-bottom: 10px;
    height: 200px;
    img {
      border-radius: 5px;
      height: 100%;
      width: 100%;
      object-fit: contain;
      transform: scale(1.4);
      // transform-origin: center 75%;
      transform-origin: center;
      @media screen and (max-width: 838px) {
        transform: scale(1.4) translateY(-15px);
      }
    }
  }
  h4 {
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    margin: 0 0 10px 0;
  }
  p {
    font-size: 14px;
    color: #ffffff;
    margin: 0;
  }
}
