.outerWrapper {
  background: linear-gradient(rgba($color: #f9f9f9, $alpha: 0), rgba($color: #e2e2e2, $alpha: 1));
  padding: 50px 0;
  display: flex;
  > div {
    width: 100%;
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > * {
    margin: 0 25px;
  }

  @media screen and (max-width: 770px) {
    flex-wrap: wrap;
    flex-direction: column;
    > .left {
      margin-bottom: 50px;
    }
    .goToTop {
      display: none;
    }
  }
}

.left {
  flex: 0.5;
  .brand {
    display: flex;
    align-items: center;
    font-size: 26px;
    margin-bottom: 25px;
    white-space: nowrap;
    img {
      width: 45px;
      height: 45px;
      margin-right: 16px;
    }
  }
  .location {
    font-size: 14px;
    color: #808080;
    margin-bottom: 50px;
    max-width: 250px;
  }
}

.right {
  display: flex;
  flex-direction: row;
  > .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.linkGroup {
  margin-right: 50px;
  margin-bottom: 50px;
  .linkGroupTitle {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .linksWrapper {
    display: flex;
    flex-direction: column;
    > a {
      margin-bottom: 10px;
      font-size: 14px;
      color: #808080;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
      &.phone {
        white-space: nowrap;
      }
    }
  }
}

.copyRight {
  color: #808080;
  font-size: 10px;
  > div {
    // font-size: 18px;
  }
}

.goToTop {
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 1;
  transition: 0.25s ease;

  border-radius: 50%;
  height: 50px;
  width: 50px;

  &:hover {
    cursor: pointer;
    background-color: #e2e2e2;
  }
}
